import * as React from 'react';
import { IDropDownMenuProps } from '../../../DropDownMenu.types';
import DropDownMenuBase from '../../DropDownMenuBase';
import MenuButton from '../../../../MenuButton/viewer/skinComps/ShinyMenuIButtonNSkin/ShinyMenuIButtonNSkin';
import styles from './ShinyMenuIButtonSkin.scss';

const ShinyMenuIButtonSkin: React.FC<IDropDownMenuProps> = props => {
  return <DropDownMenuBase {...props} styles={styles} Button={MenuButton} />;
};

export default ShinyMenuIButtonSkin;
